import React from 'react';
import styled from 'styled-components';
import { Layout, SEO, Separator, WomenPolicy, AboutThreeImages } from 'components';

export default () => (
  <Layout>
    <SEO
      pageSpecificTitle="About"
      pageSpecificDescription="Love Sport aims to increase the visibility of our female role models in sport. The heroes of today will inspire the next generation of Olympians and Paralympians."
      keywords={[
        'sport ireland',
        'love sport',
        'women in sport',
        'Roisin Upton',
        'Róisín Upton',
        'Fiona Coghlan',
        'Emma Johnstone',
        'olympics',
        'special olympics'
      ]}
    />
    <Separator includesWomenPolicyTag>
      <h4>About Love Sport</h4>
      <WomenPolicy />
    </Separator>
    <AboutThreeImages />
    <Separator>
      <h1>Love Sport</h1>
    </Separator>
    <Text>
      <p>
        Love Sport aims to increase the visibility of our female role models in sport. The heroes of
        today will inspire the next generation of Olympians and Paralympians. We want to see more
        people from all areas of society participating in sport and physical activity and enjoying
        the benefits that participation brings for everyone.
      </p>
      <br />
      <p>#LoveSport</p>
    </Text>
    <Separator>
      <h4>Contact Us</h4>
    </Separator>
  </Layout>
);

/* prettier-ignore */
const Text = styled.section`
  background-color: rgba(0, 0, 0, 0.15);
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 1.15rem;
  letter-spacing: 1px;
  padding: 4.3% 14%;
`;
